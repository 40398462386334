<template>
  <section class="repair-management" @click.stop="pickerclick">
    <div class="top-bar bg-white align-center  ">
      <el-form :inline="true" :model="formSearch" class="align-center-smini" size="mini" label-width="80px"
               :label-position="labelposition">
        <el-form-item label="账单号">
          <el-input v-model="formSearch.payBillNum" placeholder="请输入账单号"></el-input>
        </el-form-item>
        <el-form-item label="座落">
          <el-input v-model="formSearch.apartmentName" placeholder="请输入座落"></el-input>
        </el-form-item>
        <el-form-item label="合同编号">
          <el-input v-model="formSearch.contractNum" placeholder="请输入合同编号"></el-input>
        </el-form-item>
        <el-form-item label="租户姓名">
          <el-input v-model="formSearch.leasorName" placeholder="请输入租户姓名"></el-input>
        </el-form-item>
        <el-form-item label="租户电话">
          <el-input v-model="formSearch.leasorTel" placeholder="请输入租户电话"></el-input>
        </el-form-item>
        <el-form-item label="收费类型">
          <el-select ref="typeCodeRef" v-model="formSearch.typeCode" placeholder="请选择收费类型">
            <el-option label="全部" value=""/>
            <el-option v-for="(item,index) in billTypes" :key="index" :label="item.codeName"
                       v-show="item.codeName === '房租' || item.codeName === '押金' || item.codeName === '服务费'"
                       :value="item.code"/>
          </el-select>
        </el-form-item>
        <el-form-item label="支付渠道">
          <el-select ref="channelRef" v-model="formSearch.channel" placeholder="请选择支付渠道">
            <el-option label="全部" value=""/>
            <el-option v-for="(item,index) in paymentChannelTypes" :key="index"
                       :label="item.codeName" :value="item.codeName"/>
          </el-select>
        </el-form-item>
        <el-form-item label="缴费状态">
          <el-select ref="statusCodeRef" v-model="formSearch.statusCode" placeholder="请选择缴费状态">
            <el-option label="全部" value=""/>
            <el-option v-for="(item,index) in billStatusTypes" :key="index" v-show="item.codeName != '已逾期' "
                       :label="item.codeName" :value="item.code"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" style="margin: 0 7px;" @click="handleSearch">查询</el-button>
          <el-button size="small" @click="handleReset" style="margin: 0 7px;">重置</el-button>
          <el-button size="small" @click="derive" style="margin: 0 7px;">导出</el-button>
          <el-button size="small" @click="synchronization" style="margin: 0 7px;">手动同步</el-button>
        </el-form-item>
      </el-form>
      <!--      <el-input v-model="formSearch.keyword" style="margin: 5px; width: 250px" size="small" placeholder="请输入搜索"/>-->
      <!--      <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>-->
      <!--      <el-button size="small" @click="handleReset" style="margin: 0 7px;">重置</el-button>-->
      <!--      <el-button size="small" @click="handleReset" style="margin: 0 7px;">导出</el-button>-->
    </div>
    <r-e-table @selection-change="myhandleSelectionChange" ref="tableRef" :dataRequest="getLockInstallList"
               :columns="tableColumn" :query="formSearch"
               :height="730">
      <!--      <el-table-column slot="toolbar" label="操作" width="100">-->
      <!--        <template slot-scope="{ row }">-->
      <!--          <div class="table-tools">-->
      <!--            <span class="table-btn" @click="ckHandle(row)">查看附件</span>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </r-e-table>
    <r-e-dialog title="查看附件" class="layer-contract-detail" :visible.sync="dialogVisible" top="10vh">
      <div class="flex" style="height: 180px;overflow:scroll;">
        <div v-for="(item,index) in imgList" :key="index">
          <div class="flex justify-center"
               style="background: #f5f7fa;width: 225px;height: 150px;border: 1px solid #F1F1F3;border-radius: 5px;margin: 0 10px">
            <el-image :src="item.url" :preview-src-list="[item.url]" class="flex align-center">
              <div slot="error" style="font-size: 30px;">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">加载中...</div>
            </el-image>
          </div>
        </div>
      </div>
    </r-e-dialog>
  </section>
</template>

<script>
import {tableColumn} from "./data";
import {arrearsbillapi, deriveapi, synchronizationapi} from "@/api/repair-management";
import {paybillRentalExport} from "@/api/bill";
import {downloadByData} from "@/utils/hooks/download";
import {timeFormat} from "@custom";

export default {
  name: "repair-management",
  data() {
    return {
      labelposition: 'right',
      tableColumn,
      rowlist: [],
      billTypes: [],
      paymentChannelTypes: [],
      billStatusTypes: [],
      formSearch: {
        payBillNum: '',
        apartmentName: '',
        contractNum: '',
        leasorName: '',
        leasorTel: '',
        typeCode: '',
        channel: '',
        statusCode: ''
      },
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      imgList: [],
      dialogVisible: false,
    };
  },
  components: {},
  methods: {
    // 同步按钮
    synchronization() {
      synchronizationapi().then(res => {
        console.log(res)
        this.handleSearch();
      })
    },
    handleSearch(isSearch = true) {
      // 搜索
      this.$refs["tableRef"].getTableData(isSearch);
    },
    // 重置按钮
    handleReset() {
      this.formSearch = {
        payBillNum: '',
        apartmentName: '',
        contractNum: '',
        leasorName: '',
        leasorTel: '',
        typeCode: '',
        channel: '',
        statusCode: ''
      };
      Promise.resolve(this.formSearch).then(() => this.handleSearch());
      // this.handleSearch();
    },
    // 维修列表
    getLockInstallList(params) {
      return arrearsbillapi(params);
    },
    // 查看附件
    // ckHandle(data) {
    //   let that = this;
    //   const {repairGuid} = data.innerMap;
    //   const loadingOptions = that.loadingOptions;
    //   let loading = that.$loading({...loadingOptions});
    //   // repairInfo({repairGuid}).then(res => {
    //   //   const {list} = res;
    //   //   that.imgList = list;
    //   //   that.dialogVisible = true;
    //   // }).finally(() => loading.close());
    // },
    // 批量审核事件
    myhandleSelectionChange(row) {
      console.log(row)
      this.rowlist = row.map(item => {
        let {innerMap} = item
        console.log(innerMap)
        return innerMap.id
      })
    },
    derive() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出请您稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const {formSearch} = this;
      // const page = this.$refs["tableRef"].pNumber;
      // const pageSize = this.$refs["tableRef"].pSize;

      // const paramsData = {...formSearch}
      // let paramsData  ={
      //   payBillNum:'',
      //   apartmentName:'',
      //   contractNum:'',
      //   leasorName:'',
      //   leasorTel:'',
      //   typeCode:'',
      //   channel:'',
      //   statusCode:''
      // }
      // paramsData = {...}
      // console.log(paramsData)
      deriveapi(formSearch).then(res => {
        console.log(res)
        downloadByData({data: res, filename: `欠费账单管理${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
      }).finally(() => loading.close());
      console.log(this.rowlist)
    },
    // 2024-4-18-新增点击空白关闭组件
    pickerclick(e) {
      this.$refs.typeCodeRef.blur()
      this.$refs.channelRef.blur()
      this.$refs.statusCodeRef.blur()
    },
  },
  async mounted() {
    this.billStatusTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 600600);
    this.paymentChannelTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 500100);
    this.billTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 500500);
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.repair-management {
  padding: VH(15px) VW(15px);

  .top-bar {
    display: flex;
    margin: VH(10px) 0 VH(25px);
    padding: VH(15px) VW(15px);
    //justify-content: center;
  }

  /deep/ .r-e-table {
    .el-table .cell {
      //white-space: nowrap;
      text-align: center;
    }
  }
}

.align-center-smini {
  margin-top: 15px;
}

.el-input {
  width: 200px;
}

.el-select {
  width: 200px;

}
</style>